import React, {
    FC,
    useState,
    useEffect,
    useCallback,
    forwardRef,
    HTMLAttributes,
    CSSProperties,
} from 'react';
import axios from 'axios';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { ModifiedDroppable } from '../../layout/atoms/ModifiedDroppable';
import { ProjectId, ProjectRef, TaskId, WorkspaceId } from '../../core/model';
import { Environment } from '../../Environment';
import {
    addTask,
    hideCompletedTasksAction,
    idleAllPlannedTasksAction,
    TaskStateObject,
    WorkspaceState,
} from '../../store/workspaceSlice';
import Button from '../../layout/atoms/Button';
import { AppDispatch } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../layout/atoms/Dropdown';
import Container from '../../layout/atoms/Container';
import { AddTask } from '../plan/AddTask';
import { ReactComponent as MoveBackIcon } from '../../assets/icons/move-back.svg';
import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    DragOverlay,
    useSensor,
    useSensors,
    DragStartEvent,
    DragEndEvent,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styles from './Execute.module.scss';
import TaskExecute from '../../layout/atoms/TaskExecute';

export type PlannedTask = {
    task: TaskStateObject;
    color?: string | null;
    projectName?: string;
    projectRef: ProjectRef;
};

const SortableItem: FC<ItemProps> = (props) => {
    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.task.task.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    return (
        <Item
            ref={setNodeRef}
            style={style}
            withOpacity={isDragging}
            {...props}
            {...attributes}
            {...listeners}
        />
    );
};

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
    task: PlannedTask;
    workspaceId: string;
    withOpacity?: boolean;
    isDragging?: boolean;
};

const Item = forwardRef<HTMLDivElement, ItemProps>(
    ({ task, withOpacity, isDragging, style, ...props }, ref) => {
        const dispatch = useDispatch<AppDispatch>();

        const inlineStyles: CSSProperties = {
            opacity: withOpacity ? '0.5' : '1',
            transformOrigin: '50% 50%',
            cursor: isDragging ? 'grabbing' : 'grab',
            ...style,
        };

        return (
            <div ref={ref} style={inlineStyles} {...props}>
                <TaskExecute
                    key={task.task.id}
                    workspaceId={props.workspaceId}
                    projectRef={task.projectRef}
                    taskId={task.task.id as TaskId}
                    color={task.color || ''}
                    projectName={task.projectName}
                    state="planned"
                />
            </div>
        );
    }
);

const Execute = (props: { workspaceId: WorkspaceId }) => {
    const dispatch = useDispatch<AppDispatch>();
    const workspaceData: WorkspaceState = useSelector(
        (state: any) => state.workspace
    );

    const [isAddingTask, setIsAddingTask] = useState<boolean>(false);

    const activeTasks: PlannedTask[] = [
        ...workspaceData.tasks
            .filter(
                (t) => t.done || workspaceData.plannedTasks.indexOf(t.id) >= 0
            )
            .map((task) => {
                return {
                    task,
                    projectRef: {
                        workspaceId: workspaceData.id as WorkspaceId,
                        projectId: workspaceData.id as ProjectId,
                    },
                };
            }),
        ...workspaceData.projects.flatMap((p) => {
            return p.tasks
                .filter(
                    (t) =>
                        t.done || workspaceData.plannedTasks.indexOf(t.id) >= 0
                )
                .map((task) => {
                    return {
                        task,
                        color: p.color,
                        projectName: p.name,
                        projectRef: {
                            workspaceId: p.workspaceId as WorkspaceId,
                            projectId: p.id,
                        },
                    };
                });
        }),
    ].sort(
        (t1, t2) =>
            workspaceData.plannedTasks.indexOf(t1.task.id) -
            workspaceData.plannedTasks.indexOf(t2.task.id)
    );

    const partitionedTasks = activeTasks.reduce(
        (acc, plannedTask) => {
            const group: PlannedTask[] =
                acc[plannedTask.task.completedAt === null ? 0 : 1];
            group.push(plannedTask);
            return acc;
        },
        [[], []]
    );
    const _plannedTasks: PlannedTask[] = partitionedTasks[0];
    const completedTasks: PlannedTask[] = partitionedTasks[1];
    const displaySeparator =
        _plannedTasks.length > 0 && completedTasks.length > 0;
    const [plannedTasks, setPlannedTasks] = useState<PlannedTask[]>([]);
    const [items, setItems] = useState<string[]>(
        plannedTasks.map((task) => task.task.id)
    );
    const [activeId, setActiveId] = useState<string | null>(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    useEffect(() => {
        workspaceData.status === 'SUCCEEDED' && setPlannedTasks(_plannedTasks);
        workspaceData.status === 'SUCCEEDED' &&
            setItems(plannedTasks.map((task) => task.task.id));
    }, [workspaceData]);

    const hideCompletedTasks = () => {
        dispatch(hideCompletedTasksAction());
    };

    const idleAllPlannedTasks = () => {
        dispatch(idleAllPlannedTasksAction());
    };

    const putToFront = (projectRef: ProjectRef, taskId: string) => {
        return axios.put(
            `${Environment.backendUrl}/api/v1/workspace/tasks/${taskId}/put-to-front`,
            {
                workspaceId: props.workspaceId,
                projectId: projectRef.projectId,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'hustleToken'
                    )}`,
                },
            }
        );
    };

    const reorderAfterTask = (
        projectRef: ProjectRef,
        taskId: string,
        afterTaskId: TaskId
    ) => {
        return axios.put(
            `${Environment.backendUrl}/api/v1/workspace/tasks/${taskId}/reorder`,
            {
                workspaceId: props.workspaceId,
                projectId: projectRef.projectId,
                afterTaskId,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'hustleToken'
                    )}`,
                },
            }
        );
    };

    const moveTask = (taskId: string, itemIndex: number, newIndex: number) => {
        const reorderFrontend = () => {
            if (itemIndex < 0) {
                return;
            }
            const [item] = plannedTasks.splice(itemIndex, 1);
            plannedTasks.splice(newIndex, 0, item);
            setPlannedTasks([...plannedTasks]);
        };

        if (newIndex === 0) {
            putToFront(plannedTasks[itemIndex].projectRef, taskId).then(
                reorderFrontend
            );
            return;
        }

        const afterTaskId =
            plannedTasks[itemIndex > newIndex ? newIndex - 1 : newIndex].task
                .id;
        reorderAfterTask(
            plannedTasks[itemIndex].projectRef,
            taskId,
            afterTaskId as TaskId
        ).then(reorderFrontend);
    };

    // const moveTask = (taskId: string, itemIndex: number, newIndex: number) => {
    //     if (newIndex === 0) {
    //         putToFront(plannedTasks[itemIndex].projectRef, taskId);
    //     }

    //     console.log(plannedTasks);
    //     console.log(items);

    //     const afterTaskId =
    //         plannedTasks[itemIndex > newIndex ? newIndex - 1 : newIndex].task
    //             .id;
    //     reorderAfterTask(
    //         plannedTasks[itemIndex].projectRef,
    //         taskId,
    //         afterTaskId as TaskId
    //     );
    // };

    const dragEnded = (params: any) => {
        const { draggableId, source, destination } = params;
        moveTask(draggableId, source.index, destination.index);
    };

    const onQuickTasksAdded = async (tasks: string[]) => {
        for (let i = 0; i < tasks.length; i++) {
            const t = tasks[i];
            await dispatch(
                addTask({
                    workspaceId: props.workspaceId,
                    projectId: props.workspaceId,
                    name: t,
                    planned: true,
                })
            );
        }
    };

    // const handleDragStart = useCallback((event: DragStartEvent) => {
    //     setTimeout(() => setActiveId(event.active.id.toString()), 100);
    //     console.log('activeId: ' + activeId);
    //     console.log(plannedTasks);
    //     console.log(items);
    // }, []);

    // const handleDragEnd = useCallback((event: DragEndEvent) => {
    //     const { active, over } = event;

    //     console.log(active);

    //     if (active.id !== over?.id) {
    //         const oldIndex = items.indexOf(active.id.toString());
    //         const newIndex = items.indexOf(over!.id.toString());
    //         setItems((items) => {
    //             moveTask(active.id.toString(), oldIndex, newIndex);
    //             return arrayMove(items, oldIndex, newIndex);
    //         });
    //     }

    //     setActiveId(null);
    // }, []);

    // const handleDragCancel = useCallback(() => {
    //     console.log('activeId: ' + activeId);
    //     setActiveId(null);
    //     console.log(plannedTasks);
    //     console.log(items);
    // }, []);

    return (
        <>
            <Container narrow>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <AddTask
                            buttonText={'Quick add'}
                            placeholder={'Quick add a task to your workspace'}
                            onTasksAdded={onQuickTasksAdded}
                        />
                    </div>
                    {plannedTasks.length > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Dropdown position="bottomRight" horizontal={true}>
                                <Button onClick={idleAllPlannedTasks}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100vh',
                                        }}
                                    >
                                        <MoveBackIcon
                                            style={{ display: 'inline' }}
                                        />
                                        <span style={{ marginLeft: '6px' }}>
                                            Move&nbsp;all&nbsp;tasks&nbsp;to&nbsp;planning
                                        </span>
                                    </div>
                                </Button>
                            </Dropdown>
                        </div>
                    )}
                </div>
            </Container>
            {/* <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
            >
                <SortableContext items={items} strategy={rectSortingStrategy}>
                    <div>
                        {items.map((taskId) => (
                            <SortableItem
                                key={taskId}
                                task={
                                    plannedTasks.filter(
                                        (task) =>
                                            task.task.id === taskId && task
                                    )[0]
                                }
                                workspaceId={props.workspaceId}
                            />
                        ))}
                    </div>
                </SortableContext>
                <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
                    {activeId ? (
                        <Item
                            task={
                                plannedTasks.filter(
                                    (task) => task.task.id === activeId && task
                                )[0]
                            }
                            id={activeId}
                            workspaceId={props.workspaceId}
                            isDragging
                        />
                    ) : null}
                </DragOverlay>
            </DndContext> */}
            <Container narrow>
                <DragDropContext onDragEnd={dragEnded}>
                    <ModifiedDroppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {plannedTasks &&
                                    plannedTasks.map((task, index) => (
                                        <Draggable
                                            key={task.task.id}
                                            index={index}
                                            draggableId={task.task.id}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...task.projectRef}
                                                    {...provided.draggableProps}
                                                    style={
                                                        provided.draggableProps
                                                            .style
                                                    }
                                                >
                                                    <TaskExecute
                                                        key={task.task.id}
                                                        workspaceId={
                                                            props.workspaceId
                                                        }
                                                        projectRef={
                                                            task.projectRef
                                                        }
                                                        taskId={
                                                            task.task
                                                                .id as TaskId
                                                        }
                                                        color={task.color || ''}
                                                        projectName={
                                                            task.projectName
                                                        }
                                                        dragHandleProps={
                                                            provided.dragHandleProps
                                                        }
                                                        state="planned"
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                            </div>
                        )}
                    </ModifiedDroppable>
                </DragDropContext>
            </Container>
            <Container narrow>
                <>
                    {displaySeparator ? (
                        <div>
                            <small>Completed tasks</small>
                        </div>
                    ) : null}
                    {completedTasks.length > 0 ? (
                        <div>
                            <Button onClick={hideCompletedTasks}>
                                Hide completed tasks
                            </Button>
                        </div>
                    ) : null}
                </>
                {completedTasks
                    .sort((t1, t2) =>
                        t2.task.state.localeCompare(t1.task.state)
                    )
                    .map((task, index) => (
                        <TaskExecute
                            workspaceId={props.workspaceId}
                            projectRef={task.projectRef}
                            taskId={task.task.id as TaskId}
                            color={task.color || ''}
                            projectName={task.projectName}
                            key={task.task.id}
                            state="completed"
                        />
                    ))}
            </Container>
        </>
    );
};

export default Execute;
