import React from 'react';
import styles from './Container.module.scss';

export const Container = (props: {
    narrow?: boolean;
    center?: boolean;
    gap?: '0' | '16';
    children: React.ReactNode;
}) => {
    return (
        <div
            className={`${styles.wrap} ${props.narrow && styles.narrow} ${
                props.center && styles.center
            } ${styles[`gap-${props.gap}`]}`}
        >
            {props.children}
        </div>
    );
};
