import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import { fetchWorkspace } from '../store/workspaceSlice';
import { LayoutState } from '../store/layoutSlice';
import Execute from './execute/Execute';
import Plan from './plan/Plan';
import { WorkspaceId } from '../core/model';
import InitLoader from '../layout/molecules/InitLoader';
import { redirect, useNavigate } from 'react-router';

type WorkspaceState = 'EXECUTE' | 'PLAN';

const Workspace = (props: {
    workspaceId: WorkspaceId;
    workspaceState: WorkspaceState;
}) => {
    let navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const workspaceData = useSelector((state: any) => state.workspace);
    const layoutState: LayoutState = useSelector((state: any) => state.layout);
    const workspaceId = props.workspaceId;
    const workspaceState = props.workspaceState;

    const handleKeyDown = (event: any) => {
        if (event.key === '1') {
            navigate('/plan');
        } else if (event.key === '2') {
            navigate('/execute');
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (workspaceId) {
            dispatch(fetchWorkspace(workspaceId));
        }
    }, [workspaceId]);

    useEffect(() => {
        layoutState.view === 'list'
            ? document.body.classList.remove('grid-view')
            : document.body.classList.add('grid-view');
    }, [layoutState.view]);

    useEffect(() => {
        layoutState.theme === 'light'
            ? document.body.classList.remove('dark-theme')
            : document.body.classList.add('dark-theme');
    }, [layoutState.theme]);

    if (workspaceData.status === 'FAILED') {
        return (
            <div style={{ margin: '32px' }}>
                <p>Nieznany bląd aplikacji: {workspaceData.error.errorCode}</p>
            </div>
        );
    }

    return (
        <>
            {(workspaceData.status === 'IDLE' ||
                workspaceData.status === 'LOADING') && <InitLoader />}
            {workspaceState === 'PLAN' &&
                workspaceData.status === 'SUCCEEDED' && <Plan />}
            {workspaceState === 'EXECUTE' &&
                workspaceData.status === 'SUCCEEDED' && (
                    <Execute workspaceId={workspaceId} />
                )}
        </>
    );
};

export default Workspace;
