import Container from '../../atoms/Container';
import Logo from '../../atoms/Logo';
import styles from './initLoader.module.scss';

const InitLoader = (props: { withLogo?: boolean }) => {
    return (
        <Container>
            <div className={`${styles.wrap}`}>
                {props.withLogo && <Logo />}
                <div className={`${styles.texts}`}>
                    <div className={`loader-animation`}>
                        <p>loading...</p>
                        <p>connecting tasks with projects...</p>
                        <p>setting up somethiing else...</p>
                        <p>checking best possible ways...</p>
                        <p>calculating your gains...</p>
                        <p>setting up your goals...</p>
                        <p>we think something could go wrong</p>
                        <p>sorry for that</p>
                        <p>maybe try to reload the page</p>
                        <p>or try again later</p>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default InitLoader;
