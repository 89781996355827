import { useState } from 'react';
import { clsx } from 'clsx';

import styles from './TaskCheckbox.module.scss';
import { ReactComponent as CheckmarkSvg } from '../../../assets/icons/checkmark.svg';
import successSoundYay from '../../../assets/sounds/success-yay.mp3';

const TaskCheckbox = (props: {
    color?: string;
    checked: boolean;
    onClick: any;
}) => {
    const [checked, setChecked] = useState<boolean>(props.checked);

    const playSound = () => {
        new Audio(successSoundYay).play();
    };

    const onClicked = () => {
        !checked && playSound();
        let newValue = !checked;
        setChecked(newValue);

        setTimeout(() => props.onClick(newValue), 500);
    };

    return (
        <div
            className={clsx(styles.wrap, { [styles.checked]: checked })}
            style={{ color: props.color }}
            onClick={onClicked}
        >
            <div
                className={styles.background}
                style={{ backgroundColor: props.color }}
            />
            <CheckmarkSvg className={styles.checkmark} />
            <input
                type="checkbox"
                name="name"
                value="value"
                checked={checked}
                className={styles.checkbox}
            />
        </div>
    );
};

export default TaskCheckbox;
